import React, { Component } from "react";
import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import moment from "moment";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";

import "@scss/page.scss";
import "@scss/press.scss";

export const pressQuery = graphql`
  query pressList($uid: String) {
    allPrismicPress(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title {
              text
            }
          }
          uid
        }
      }
    }
    allPrismicArticle(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            headline {
              html
              text
              raw
            }
            byline
            excerpt {
              html
              text
              raw
            }
            date
            image {
              alt
              copyright
              url
            }
            article_link {
              link_type
              ... on PrismicLinkType {
                url
              }
              ... on PrismicLinkType {
                uid
              }
            }
          }
          uid
        }
      }
    }
  }
`;

const PressList = (props) => {
  var data = props.data;
  const currentPage = get(data, "allPrismicPress.edges[0].node");
  const allArticles = get(data, "allPrismicArticle.edges");
  return (
    <Layout>
      <div className="general-page press" id={currentPage.uid}>
        <section>
          {currentPage.data.title && (
            <h2>{RichText.asText(currentPage.data.title)}</h2>
          )}
          <ul className="press__list">
            {allArticles &&
              allArticles.map((article, index) => {
                return <ArticleSlat key={index} article={article} />;
              })}
          </ul>
        </section>
      </div>
    </Layout>
  );
};

class ArticleSlat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    var { article } = this.props;
    var date = moment(article.node.data.date);
    var { headline, excerpt, image } = article.node.data;
    var external =
      article.node.data.article_link.link_type === "Web" ? true : false;
    return (
      <article className="press__article">
        {image && !external && (
          <Link to={article.node.data.article_link.url}>
            <img src={image.url} />
          </Link>
        )}
        {image && external && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={article.node.data.article_link.url}
          >
            <img src={image.url} />
          </a>
        )}
        {headline && external && (
          <h3>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={article.node.data.article_link.url}
            >
              {RichText.asText(headline.raw)}
            </a>
          </h3>
        )}
        {headline && !external && (
          <h3>
            <Link to={article.node.data.article_link.url}>
              {RichText.asText(headline.raw)}
            </Link>
          </h3>
        )}
        <time>{date.format("dddd, MMMM Do YYYY")}</time>
        {excerpt && <div>{RichText.asText(excerpt.raw)}</div>}
        <div className="press__article--cta">
          {external && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={article.node.data.article_link.url}
            >
              Read Article
            </a>
          )}
        </div>
      </article>
    );
  }
}

export default PressList;
